<template>
  <div v-loading="loading" class="card">
    <div
      class="card-body mode-selector d-flex flex-column gap-4 justify-content-center align-items-center"
      :class="{ disabled }"
      @click="$emit('click')"
    >
      <div class="title-icon">
        <slot name="icon" />
      </div>
      <div class="d-flex flex-column mode-selector-text align-items-center">
        <p class="mode-selector-title">
          {{ title }}
        </p>
        <p class="mode-selector-description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.mode-selector.disabled {
  background-color: #f3f3f4;
  cursor: not-allowed;

  &:active {
    background-color: #f3f3f4;
  }

  .title-icon {
    color: $typography-disabled;
    opacity: 0.4;
  }

  .mode-selector-text {
    .mode-selector-title,
    .mode-selector-description {
      color: $typography-disabled;
    }
  }
}

.mode-selector {
  width: 16.25rem;
  height: 9.5rem;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 6px;
  background-color: #f3f3f4;

  &:hover {
    background-color: #e3e4e6 !important;
  }

  &:active {
    background-color: #d2d4d7 !important;
  }

  .mode-selector-text {
    gap: 0.25rem;
    .mode-selector-title {
      color: $typography-primary;
      font-weight: 550;
      line-height: 1.25rem;
      text-align: center;
    }
    .mode-selector-description {
      color: $typography-secondary;
      line-height: 1rem;
      font-size: 0.75rem;
      text-align: center;
    }
  }
}
</style>
